import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ContactArea from '../containers/global/contact-area/contact-four'

const TermsPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Terms and Conditions" />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Terms and Conditions"
    />
    <main className="site-wrapper-reveal">
        <div class="container accordian-container">
          <br/> <br/>
          <h3 class="text-center">Terms and Conditions</h3>
          <p>
            <b>Best Advice</b> - Decluttering focuses on deciding which of the client’s possessions and items they want to keep. The decision to keep or discard an item sits entirely with the client. Neat Bird will guide the client and help them determine what they need within their home. Advice is given in good faith, and it is always the client’s decision to accept guidance on whether to keep possessions or part with them. Neat Bird cannot accept responsibility for the consequences of such decisions and cannot be held liable for any possessions or items that have been discarded.
            <br/><br/>
            <b>Privacy & Confidentiality</b> -  Neat Bird offers complete confidentiality and discretion, and will never divulge information to third parties without the client’s consent. Neat Bird’s Privacy Policy governs the handling of the client’s personal information.
            <br/><br/>
            To safeguard Neat Bird’s own personal safety, a close family member will always have access to Neat Bird’s appointments when meeting and working with clients in their own homes. This information is not shared with anyone else.
            <br/><br/>
            Neat Bird is fully compliant with all relevant legislation and is registered with the Information Commissioner's Office (ICO). By entering into a contract with Neat Bird, the client agrees that their personal details will be held securely and will only be used in connection with the services carried out by Neat Bird.
            <br/><br/>
            <b>Insurance</b> - Neat Bird holds Public Liability and Professional Indemnity Insurance with Westminster Global Insurance.
            <br/><br/>
            Neat Bird provides in-home service, and whilst every care will be taken with the client’s possessions whilst in their home, Neat Bird cannot be held liable for any direct or consequential loss or damage. It is the client’s responsibility to ensure that they have adequate and appropriate insurance for their possessions.
            <br/><br/>
            <b>Payment Terms</b> - Full cleared payment is required 48hrs in advance of the client’s session.

            <br/><br/>
            Neat Bird accepts payment by bank transfer using the bank details shown on the client’s invoice.
            <br/><br/>
            On receipt of payment for a booked session, Neat Bird will travel free of charge to clients’ homes within a 30-mile radius of their business postcode. 
            <br/><br/>
            For any journey over 30 miles (60 miles in total – to and from the client’s home), expenses of 47p per mile will be charged for every additional mile.
            <br/><br/>
            Neat Bird has a standard organiser kit of basic supplies and equipment, e.g., a label maker, labels, refuse sacks, charity bags etc. However, all other equipment needed within the session, e.g., storage boxes, baskets, shelving etc, will be the responsibility of the client. Please note that the need for additional equipment or supplies will be discussed in advance of the client’s booked session.
            <br/><br/>
            Neat Bird is not registered for VAT at present.
            <br/><br/>
            Neat Bird reserves the right to increase its rates at any time. Any sessions booked before a rate increase will not be affected by the rise.

            <br/><br/>
            <b>Item Removal</b> - Neat Bird will remove up to 2 bin bags of unwanted possessions on the day of the session. Any extra bags will incur a charge of £15, up to a total of 5 bags (or whatever will fit into the car!)
            Neat Bird encourages recycling items where possible or making donations to registered charities. The client may even choose to sell possessions or items that they no longer need, but Neat Bird cannot provide a valuation service.

            <br/><br/>
            <b>Working Hours</b> - Neat Bird will only charge the client for the hours that are actually worked and not for their travel time to and from the client’s home. 
            <br/><br/>
            Please note that Neat Bird will bring their own refreshments, snacks and/or lunch.
            <br/><br/>
            <b>Cleaning</b> - Neat Bird does not provide cleaning services. However, Neat Bird will always endeavour to leave their client’s home in an orderly and organised condition.
            <br/><br/>
            <b>Recommendations</b> - Neat Bird may make suggestions on additional products and services by third parties. Neat Bird will always do their best to ensure they recommend high-quality products and services but cannot accept any responsibility or liability should the products or services cause the client any direct or consequential loss or damage.
            <br/><br/>
            <b>Cancellation Policy</b> - On occasion, either party may need to reschedule a booked session. There will be no charge for this if all rescheduled sessions are completed within 2 months of the original session time and date, and at least 24 hours’ notice is given. 
            <br/><br/>
            Cancellations made more than 48 hours in advance of the session will be refunded in full.
            <br/><br/>
            Any cancellation within less than two clear calendar days’ notice will be refunded 50% of the price paid. 
            <br/><br/>
            ​Cancellations made on the same calendar day as the booked session will not be refunded.
            <br/><br/>
            Cancellations of rescheduled sessions are not eligible for a refund, irrespective of notice given.
            <br/><br/>
            <b>Access</b> - Please ensure there is good access immediately outside the client’s home and inside their home.
            <br/><br/>
            Should the working area be considered unsafe, Neat Bird reserves the right to cancel the session immediately without a refund.
            <br/><br/>
            <b>Photography</b> - With the client’s permission, Neat Bird will use before and after photographs to support a client’s decluttering and organisation journey.
            <br/><br/>
            Neat Bird will always obtain the client’s written consent before any images of the client’s space, home, or office are published on Neat Bird’s website and social media.
            <br/><br/>

            <b>Agreement</b> - A full contract containing all Neat Bird’s terms and conditions will be sent over to be signed electronically ahead of the session.
          </p>
        </div>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default TermsPage
 